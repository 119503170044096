import React from 'react';
import './index.scss';
import BookmarkImage from '@/widgets/BookmarkImage/BookmarkImage';
import DateChip from '@/shared/ui/DateChip/DateChip';
import { ServerApiEventsClientDtoEventsListResponseDTO } from '@/shared/api/gen/data-contracts';
import { useNavigate } from 'react-router-dom';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	event: ServerApiEventsClientDtoEventsListResponseDTO;
	dateChipClassName?: string;
}

const EventCardWithDescription = ({ event, dateChipClassName, ...props }: Props) => {
	const navigate = useNavigate();
	const startDate = new Date(event.start_at);
	const endDate = new Date(event.end_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	};
	return (
		<div
			{...props}
			className='EventCardWithDescription'>
			{/* TODO: change to square */}
			<BookmarkImage
				id={event.id}
				image={event.square_banner || event.preview || ''}
				onClick={() => navigate(`/events/${event?.id}`)}
			/>
			<DateChip className={`EventCardWithDescription_date mt-8 ${dateChipClassName}`}>
				<span className='text-12 medium'>{startDate.toLocaleDateString('ru-RU', dateOptions)}</span>
				{startDate.getDate() !== endDate.getDate() && (
					<span className='text-12 medium'> - {endDate.toLocaleDateString('ru-RU', dateOptions)}</span>
				)}
			</DateChip>
			<h2 className='text-14 semibold mt-8'>{event?.title}</h2>
		</div>
	);
};

export default EventCardWithDescription;
