import React from 'react';
import './index.scss';
import Chip from "@/shared/ui/chip/Chip";
import {EventsResponseDTO, ServerApiTagsClientDtoTagsListResponseDTO} from "@/shared/api/gen/data-contracts";

type Props = {
    tags: string[],
}

const EventTags = ({tags}: Props) => {
    return (
        <div className='EventRegistrationTags'>
            {tags.map(tag => {
                return <Chip className='EventRegistrationTags_chip'>
                    <span className='text-14 regular'>{tag}</span>
                </Chip>
            })}
        </div>
    );
};

export default EventTags;