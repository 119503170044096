import React from 'react';
import BackgroundAnimation from '../animations/BackgroundAnimation/BackgroundAnimation';
import { motion } from 'framer-motion';
import { FlexColumn } from '../layouts/Flex/Flex';
import closeIcon from 'public/images/close.png';
import './index.scss';

interface Props {
	close: () => void;
	children: React.ReactElement;
	style?: object;
}

const Modal = ({ close, children, style }: Props) => {
	return (
		<>
			<BackgroundAnimation setVisible={close} />
			<motion.div
				style={{
					display: 'flex',
					position: 'fixed',
					left: '0px',
					bottom: '0px',
					width: '100%',
					minHeight: '90svh',
					zIndex: 15,
					...style,
				}}
				initial={{ y: '100svh' }}
				animate={{ y: '0svh' }}
				exit={{ y: '100svh' }}
				transition={{ ease: 'easeInOut', duration: 0.5 }}>
				<FlexColumn
					className='ModalContent invisibleScroll'
					style={{ height: '90svh', justifyContent: 'flex-start', overflowY: 'scroll' }}>
					<img
						className='ModalContent__close'
						src={closeIcon}
						alt='close'
						onClick={close}
					/>
					{children}
				</FlexColumn>
			</motion.div>
		</>
	);
};

export default Modal;
