/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EventFileType,
  EventLinksListResponseDTO,
  EventLinksResponseDTO,
  EventRegistrationStatus,
  EventStatus,
  HTTPValidationError,
  ServerApiEventsClientDtoEventsListResponseDTO,
  ServerApiEventsClientDtoEventsResponseDTO,
  ServerApiEventsClientDtoUserEventFeedbackResponseDTO,
  ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO,
  ServerApiEventsEventFilesClientDtoEventFilesResponseDTO,
  ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO,
  UserEventFeedbackCreateDTO,
  WishlistedEventFilesResponseDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Events<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetEventsApiEventsGet
   * @summary Get Events
   * @request GET:/api/events
   * @response `200` `(ServerApiEventsClientDtoEventsListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventsApiEventsGet = (
    query?: {
      /** Organization Ref */
      organization_ref?: number | null;
      /** Tag Refs */
      tag_refs?: number[] | null;
      /** In Wishlist */
      in_wishlist?: boolean | null;
      /** Upcoming */
      upcoming?: boolean | null;
      /** Event Status List */
      event_status_list?: EventStatus[] | null;
      /** Registration Status List */
      registration_status_list?: EventRegistrationStatus[] | null;
      /** Start In Range */
      start_in_range?: any[] | null;
      /** Limit */
      limit?: number | null;
      /** Offset */
      offset?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiEventsClientDtoEventsListResponseDTO[], HTTPValidationError>({
      path: `/api/events`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetEventsDetailApiEventsIdGet
   * @summary Get Events Detail
   * @request GET:/api/events/{_id}
   * @response `200` `ServerApiEventsClientDtoEventsResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventsDetailApiEventsIdGet = (id: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsClientDtoEventsResponseDTO, HTTPValidationError>({
      path: `/api/events/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetIcsFileApiEventsIcsHashIcsGet
   * @summary Get Ics File
   * @request GET:/api/events/{ics_hash}/ics
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getIcsFileApiEventsIcsHashIcsGet = (icsHash: string, params: RequestParams = {}) =>
    this.request<string, HTTPValidationError>({
      path: `/api/events/${icsHash}/ics`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetIcsHashApiEventsEventIdIcsHashGet
   * @summary Get Ics Hash
   * @request GET:/api/events/{event_id}/ics_hash
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getIcsHashApiEventsEventIdIcsHashGet = (eventId: number, params: RequestParams = {}) =>
    this.request<string, HTTPValidationError>({
      path: `/api/events/${eventId}/ics_hash`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name CreateEventUserFeedbackApiEventsEventIdFeedbackPost
   * @summary Create Event User Feedback
   * @request POST:/api/events/{event_id}/feedback
   * @response `200` `ServerApiEventsClientDtoUserEventFeedbackResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  createEventUserFeedbackApiEventsEventIdFeedbackPost = (
    eventId: number,
    data: UserEventFeedbackCreateDTO,
    params: RequestParams = {},
  ) =>
    this.request<ServerApiEventsClientDtoUserEventFeedbackResponseDTO, HTTPValidationError>({
      path: `/api/events/${eventId}/feedback`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetEventUserFeedbackApiEventsEventIdFeedbackGet
   * @summary Get Event User Feedback
   * @request GET:/api/events/{event_id}/feedback
   * @response `200` `(ServerApiEventsClientDtoUserEventFeedbackResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventUserFeedbackApiEventsEventIdFeedbackGet = (eventId: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsClientDtoUserEventFeedbackResponseDTO[], HTTPValidationError>({
      path: `/api/events/${eventId}/feedback`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetMonthEventsCalendarApiEventsMonthEventsCalendarGet
   * @summary Get Month Events Calendar
   * @request GET:/api/events/month_events_calendar
   * @response `200` `(string)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getMonthEventsCalendarApiEventsMonthEventsCalendarGet = (
    query: {
      /**
       * Year Month
       * @format date
       */
      year_month: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string[], HTTPValidationError>({
      path: `/api/events/month_events_calendar`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name RegisterOnEventApiEventsRegistrationEventEventIdPut
   * @summary Register On Event
   * @request PUT:/api/events/registration/event/{event_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  registerOnEventApiEventsRegistrationEventEventIdPut = (eventId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/registration/event/${eventId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name RegisterWithTicketIdApiEventsRegistrationTicketIdPut
   * @summary Register With Ticket Id
   * @request PUT:/api/events/registration/{ticket_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  registerWithTicketIdApiEventsRegistrationTicketIdPut = (ticketId: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/registration/${ticketId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name GetEventRegistrationDetailApiEventsRegistrationEventIdGet
   * @summary Get Event Registration Detail
   * @request GET:/api/events/registration/{event_id}
   * @response `200` `ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventRegistrationDetailApiEventsRegistrationEventIdGet = (eventId: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO, HTTPValidationError>({
      path: `/api/events/registration/${eventId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventFiles
   * @name GetEventFilesApiEventsEventFilesGet
   * @summary Get Event Files
   * @request GET:/api/events/event_files
   * @response `200` `(ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventFilesApiEventsEventFilesGet = (
    query: {
      /** Event Ref */
      event_ref: number;
      /**
       * In Wishlist
       * @default false
       */
      in_wishlist?: boolean;
      /** File Type */
      file_type?: EventFileType | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO[], HTTPValidationError>({
      path: `/api/events/event_files`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventFiles
   * @name GetWishlistedEventFilesApiEventsEventFilesWishlistedGet
   * @summary Get Wishlisted Event Files
   * @request GET:/api/events/event_files/wishlisted
   * @response `200` `(WishlistedEventFilesResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getWishlistedEventFilesApiEventsEventFilesWishlistedGet = (
    query?: {
      /** File Type */
      file_type?: EventFileType | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<WishlistedEventFilesResponseDTO[], HTTPValidationError>({
      path: `/api/events/event_files/wishlisted`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventFiles
   * @name GetEventFileDetailApiEventsEventFilesIdGet
   * @summary Get Event File Detail
   * @request GET:/api/events/event_files/{_id}
   * @response `200` `ServerApiEventsEventFilesClientDtoEventFilesResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventFileDetailApiEventsEventFilesIdGet = (id: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsEventFilesClientDtoEventFilesResponseDTO, HTTPValidationError>({
      path: `/api/events/event_files/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventFiles
   * @name AddEventFileToWishlistApiEventsEventFilesWishlistFileIdPost
   * @summary Add Event File To Wishlist
   * @request POST:/api/events/event_files/wishlist/{file_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addEventFileToWishlistApiEventsEventFilesWishlistFileIdPost = (fileId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/event_files/wishlist/${fileId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventFiles
   * @name RemoveEventFileFromWishlistApiEventsEventFilesWishlistFileIdDelete
   * @summary Remove Event File From Wishlist
   * @request DELETE:/api/events/event_files/wishlist/{file_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeEventFileFromWishlistApiEventsEventFilesWishlistFileIdDelete = (fileId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/event_files/wishlist/${fileId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventLinks
   * @name GetEventLinksApiEventsEventLinksGet
   * @summary Get Event Links
   * @request GET:/api/events/event_links
   * @response `200` `(EventLinksListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventLinksApiEventsEventLinksGet = (
    query: {
      /** Event Ref */
      event_ref: number;
      /**
       * In Wishlist
       * @default false
       */
      in_wishlist?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<EventLinksListResponseDTO[], HTTPValidationError>({
      path: `/api/events/event_links`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventLinks
   * @name GetEventFileDetailApiEventsEventLinksIdGet
   * @summary Get Event File Detail
   * @request GET:/api/events/event_links/{_id}
   * @response `200` `EventLinksResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventFileDetailApiEventsEventLinksIdGet = (id: number, params: RequestParams = {}) =>
    this.request<EventLinksResponseDTO, HTTPValidationError>({
      path: `/api/events/event_links/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventLinks
   * @name AddEventFileToWishlistApiEventsEventLinksWishlistLinkIdPost
   * @summary Add Event File To Wishlist
   * @request POST:/api/events/event_links/wishlist/{link_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addEventFileToWishlistApiEventsEventLinksWishlistLinkIdPost = (linkId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/event_links/wishlist/${linkId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventLinks
   * @name RemoveEventFileFromWishlistApiEventsEventLinksWishlistLinkIdDelete
   * @summary Remove Event File From Wishlist
   * @request DELETE:/api/events/event_links/wishlist/{link_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeEventFileFromWishlistApiEventsEventLinksWishlistLinkIdDelete = (linkId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/event_links/wishlist/${linkId}`,
      method: "DELETE",
      ...params,
    });
}
