import React, {SetStateAction, useState} from "react";
import './index.scss';
import dropdown from 'public/images/arrow_down.png';
import {ServerApiTagsClientDtoTagsListResponseDTO, TagGroupsListResponseDTO} from "@/shared/api/gen/data-contracts";
import {CheckboxInputChip} from "@/shared/ui/inputs/ChecboxInputs/Checkbox";

type Props = {
    title: string,
    onCLick: () => void
}

const DropdownFilter = ({title, onCLick}: Props) => {


    return(
        <div className='DropdownFilter'
            onClick={onCLick}
        >
            <span className='text-14 medium'>{title}</span>
            <img src={dropdown} alt='dropdown'/>
        </div>
    )
}

export default DropdownFilter;