import React, { useCallback, useState } from 'react';
import './index.scss';
import Tab, { TabVariant } from '@/shared/ui/Tab/Tab';
import { useNavigate, useParams } from 'react-router-dom';
import FileMaterial from '@/shared/ui/eventMaterials/FileMaterial/FileMaterial';
import LinkMaterial from '@/shared/ui/eventMaterials/LinkMaterial/LinkMaterial';
import PhotoMaterial from '@/shared/ui/eventMaterials/PhotoMaterial/PhotoMaterial';
import torus from 'public/images/Torus.png';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import PhotoPage from '@/pages/PhotoPage';
import useFetch from '@/shared/hooks/useFetch';
import {
	EventFileType,
	EventLinksListResponseDTO,
	ServerApiEventsClientDtoEventsResponseDTO,
	ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO,
} from '@/shared/api/gen/data-contracts';
import api from '@/shared/api/api';
import { EnvConfig } from '@/app/config';
import useTG from '@/shared/hooks/useTG';
import { defaultButtonClickHandler } from '@/shared/ui/layouts/PageLayout';

export enum MaterialsTabs {
	PHOTO = 'Фото',
	FILES = 'Файлы',
	LINKS = 'Ссылки',
}

const EventMaterials = () => {
	const { WebApp } = useTG({ requestWriteAccess: true });
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const eventId = id as unknown as number;
	const [activeTab, setActiveTab] = useState<MaterialsTabs>(MaterialsTabs.PHOTO);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isGalleryActive, setIsGalleryActive] = useState<boolean>(false);
	const { data: event } = useFetch<ServerApiEventsClientDtoEventsResponseDTO>(
		() => api.events.getEventsDetailApiEventsIdGet(eventId),
		undefined,
		[]
	);
	const { data: photos, refresh: photosRefresh } = useFetch<
		ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO[]
	>(
		() => api.events.getEventFilesApiEventsEventFilesGet({ event_ref: eventId, file_type: EventFileType.Image }),
		[],
		[]
	);
	const { data: files, refresh: filesRefresh } = useFetch<
		ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO[]
	>(
		() => api.events.getEventFilesApiEventsEventFilesGet({ event_ref: eventId, file_type: EventFileType.File }),
		[],
		[]
	);
	const { data: links, refresh: linksRefresh } = useFetch<EventLinksListResponseDTO[]>(
		() => api.events.getEventLinksApiEventsEventLinksGet({ event_ref: eventId }),
		[],
		[]
	);
	const handlePhotoClick = (index: number) => {
		setIsGalleryActive(true);
		setCurrentIndex(index);
	};
	const defaultHandler = useCallback(() => {
		defaultButtonClickHandler(navigate);
	}, [navigate]);
	const galleryHandler = useCallback(() => {
		setIsGalleryActive(false);
	}, []);
	React.useEffect(() => {
		if (WebApp?.onEvent) {
			if (isGalleryActive) {
				WebApp?.BackButton?.show();
				WebApp?.onEvent('backButtonClicked', galleryHandler);
				return () => {
					WebApp?.offEvent('backButtonClicked', galleryHandler);
					WebApp?.BackButton?.hide();
				};
			} else {
				WebApp?.BackButton?.show();
				WebApp?.onEvent('backButtonClicked', defaultHandler);
				return () => {
					WebApp?.offEvent('backButtonClicked', defaultHandler);
					WebApp?.BackButton?.hide();
				};
			}
		}
	}, [isGalleryActive, WebApp]);
	if (isGalleryActive)
		return (
			<PhotoPage
				photos={photos}
				currentIndex={currentIndex}
				setCurrentIndex={setCurrentIndex}
			/>
		);
	return (
		<div className='EventsMaterials invisibleScroll'>
			<h1 className='text-22 medium'>Материлы мероприятия</h1>
			<img
				src={`${EnvConfig.HOST_URL}/${event?.preview}`}
				alt='preview'
				className='mt-16'
			/>
			<div className='EventsMaterials_tabs mt-16'>
				{photos?.length > 0 && (
					<Tab
						text={MaterialsTabs.PHOTO}
						theme={MaterialsTabs.PHOTO == activeTab ? TabVariant.active : TabVariant.default}
						setActive={setActiveTab}
					/>
				)}
				{files?.length > 0 && (
					<Tab
						text={MaterialsTabs.FILES}
						theme={MaterialsTabs.FILES == activeTab ? TabVariant.active : TabVariant.default}
						setActive={setActiveTab}
					/>
				)}
				{links?.length > 0 && (
					<Tab
						text={MaterialsTabs.LINKS}
						theme={MaterialsTabs.LINKS == activeTab ? TabVariant.active : TabVariant.default}
						setActive={setActiveTab}
					/>
				)}
			</div>
			{activeTab == MaterialsTabs.PHOTO && (
				<div className='EventsMaterials_photo mt-16'>
					{photos?.map((photo, index) => {
						return (
							<PhotoMaterial
								className='EventsMaterials_photo__item'
								index={index}
								photo={photo}
								handleClick={handlePhotoClick}
								refresh={photosRefresh}
								onLoad={(
									ref: React.RefObject<HTMLDivElement>,
									e: React.SyntheticEvent<HTMLImageElement, Event>
								) => {
									if (e?.target?.naturalWidth > e?.target?.naturalHeight) {
										ref?.current?.classList?.add('landscape');
									}
								}}
							/>
						);
					})}
				</div>
			)}
			{activeTab == MaterialsTabs.FILES && (
				<div className='EventsMaterials_files mt-16'>
					{files.map((file) => {
						return (
							<FileMaterial
								file={file}
								refresh={filesRefresh}
							/>
						);
					})}
				</div>
			)}
			{activeTab == MaterialsTabs.LINKS && (
				<div className='EventsMaterials_links mt-16'>
					{links?.map((link) => {
						return (
							<LinkMaterial
								link={link}
								refresh={linksRefresh}
							/>
						);
					})}
				</div>
			)}
			{false && (
				<div className='EventsMaterials_empty mt-70'>
					<img
						src={torus}
						alt='torus'
					/>
					<h1 className='text-28 medium mt-16'>В материалах пока пусто</h1>
					<h2 className='text-18 medium mt-12'>Совсем скоро мы добавим новые файлы к мероприятию</h2>
					<CustomButton
						className='mt-32'
						theme={ButtonVariant.grey}
						style={{ width: 'fit-content' }}
						onClick={() => navigate(`/events/${eventId}`)}>
						<span className='text 16 medium'>Вернуться к мероприятию</span>
					</CustomButton>
				</div>
			)}
		</div>
	);
};

export default EventMaterials;
