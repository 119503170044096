import React, { useState } from 'react';
import './general.scss';
import { FlexColumn, FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import loadBullet from 'public/images/LoadBullet.png';

export const LoadComponent = () => (
	<FlexColumn style={{ alignItems: 'center', justifyContent: 'center', height: '100svh', gap: '35px' }}>
		<FlexRow style={{ width: '100%', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
			<img
				className='LoadBullet_1'
				src={loadBullet}
				alt=''
			/>
			<img
				className='LoadBullet_2'
				src={loadBullet}
				alt=''
			/>
			<img
				className='LoadBullet_3'
				src={loadBullet}
				alt=''
			/>
		</FlexRow>
		<h1 className='text-22 regular'>Идет загрузка ...</h1>
	</FlexColumn>
);

const LoadPage = () => {
	const [delay, setDelay] = useState(true);

	React.useEffect(() => {
		setTimeout(() => setDelay(false), 1500);
	}, []);
	if (delay) return null;
	return <LoadComponent />;
};

export default LoadPage;
