import React, { useState } from 'react';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import Grid from '@/shared/ui/layouts/Grid/Grid';
import './index.scss';
import { FlexColumn } from '@/shared/ui/layouts/Flex/Flex';
import api from '@/shared/api/api';
import useFetch from '@/shared/hooks/useFetch';
import CompanyModal from './CompanyModal';
import { AnimatePresence } from 'framer-motion';
import { getShortText } from '@/shared/utils/HOC/getShortText';
import { OrganizationsListResponseDTO } from '@/shared/api/gen/data-contracts';

interface ICompanyCard extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	description: string;
	image: string;
}
export function CompanyCard({ description, image, ...props }: ICompanyCard) {
	return (
		<div
			className='CompanyCard'
			{...props}>
			<img
				className='CompanyCard__image'
				src={image}
				alt=''
			/>
			<p className='CompanyCard__description text-12'>{description}</p>
		</div>
	);
}
export function AboutPageContent() {
	const { data: organizations } = useFetch(() => api.organizations.getOrganizationsApiOrganizationsGet(), [], []);
	const [org, setOrg] = useState<OrganizationsListResponseDTO | null>(null);
	return (
		<>
			<FlexColumn className='p-16 g-16'>
				<h1
					className='text-22'
					style={{ textAlign: 'left', width: '100%' }}>
					Группа компаний
				</h1>
				<Grid
					style={{ width: '100%' }}
					className='py-16'>
					{organizations.map((el) => (
						<CompanyCard
							key={el.id}
							image={el.logo}
							description={getShortText(el.description, {}).text}
							onClick={() => {
								setOrg(el);
							}}
						/>
					))}
				</Grid>
			</FlexColumn>
			<AnimatePresence>
				{!!org && (
					<CompanyModal
						organization={org}
						close={() => setOrg(null)}
					/>
				)}
			</AnimatePresence>
		</>
	);
}
function AboutPage() {
	return (
		<PageLayout
			backgroundStyles={{ background: '#f1f5f9' }}
			navbar
			background>
			<AboutPageContent />
		</PageLayout>
	);
}

export default AboutPage;
