import React from 'react';
import './index.scss';

const Background = ({ ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	return (
		<div
			{...props}
			className='Background_wrapper'>
			<div
				className='Background'
				{...props}>
				<div className='Background_circle1' />
				<div className='Background_circle2' />
				<div className='Background_circle3' />
				<div className='Background_circle4' />
				<div className='Background_circle5' />
				<div className='Background_circle6' />
			</div>
		</div>
	);
};

export default Background;
