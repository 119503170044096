/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AdminsCreateDTO */
export interface AdminsCreateDTO {
  /** Fullname */
  fullname: string;
  /** Client Id */
  client_id: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
}

/** AdminsListResponseDTO */
export interface AdminsListResponseDTO {
  /** Id */
  id: number;
  /** Fullname */
  fullname: string;
  /** Client Id */
  client_id: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** AdminsResponseDTO */
export interface AdminsResponseDTO {
  /** Id */
  id: number;
  /** Fullname */
  fullname: string;
  /** Client Id */
  client_id: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
}

/** AdminsUpdateDTO */
export interface AdminsUpdateDTO {
  /** Fullname */
  fullname: string;
  /** Client Id */
  client_id: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
}

/** Body_create_events_api_admin_events_post */
export interface BodyCreateEventsApiAdminEventsPost {
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: File | string | null;
  /** Square Banner */
  square_banner?: File | string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Location */
  location: string;
  /** Help Url */
  help_url?: string | null;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /**
   * Tags Ref List
   * @default []
   */
  tags_ref_list?: number[];
}

/** Body_create_notifications_api_admin_notifications_post */
export interface BodyCreateNotificationsApiAdminNotificationsPost {
  /** Title */
  title: string;
  /** Text */
  text: string;
  /**
   * Schedule Datetime
   * @format date-time
   */
  schedule_datetime: string;
  /**
   * User Refs
   * @default []
   */
  user_refs?: number[];
  /** File */
  file?: File | string | null;
  /**
   * User Status List
   * @default []
   */
  user_status_list?: UserStatus[];
  /** Event Registration Status Notification List */
  event_registration_status_notification_list?: string | null;
}

/** Body_create_organizations_api_admin_organizations_post */
export interface BodyCreateOrganizationsApiAdminOrganizationsPost {
  /** Title */
  title: string;
  /** Mail */
  mail?: string | null;
  /** Url */
  url?: string | null;
  /** Logo */
  logo?: File | string | null;
  /** Telegram Chat Id */
  telegram_chat_id: number;
  /** Description */
  description: string;
  /** Socials */
  socials?: string | null;
  /** Index */
  index: number;
}

/** Body_update_events_api_admin_events___id__put */
export interface BodyUpdateEventsApiAdminEventsIdPut {
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: File | string | null;
  /** Square Banner */
  square_banner?: File | string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Help Url */
  help_url?: string | null;
  /** Location */
  location: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /**
   * Tags Ref List
   * @default []
   */
  tags_ref_list?: number[];
}

/** Body_update_notifications_api_admin_notifications___id__put */
export interface BodyUpdateNotificationsApiAdminNotificationsIdPut {
  /** Title */
  title: string;
  /** Text */
  text: string;
  /**
   * Schedule Datetime
   * @format date-time
   */
  schedule_datetime: string;
  /**
   * User Refs
   * @default []
   */
  user_refs?: number[];
  /** File */
  file?: File | string | null;
  /**
   * User Status List
   * @default []
   */
  user_status_list?: UserStatus[];
  /** Event Registration Status Notification List */
  event_registration_status_notification_list?: string | null;
}

/** Body_update_organizations_api_admin_organizations___id__put */
export interface BodyUpdateOrganizationsApiAdminOrganizationsIdPut {
  /** Title */
  title: string;
  /** Mail */
  mail?: string | null;
  /** Url */
  url?: string | null;
  /** Logo */
  logo?: File | string | null;
  /** Description */
  description: string;
  /** Socials */
  socials?: string | null;
  /** Index */
  index: number;
}

/** Body_upload_avatar_api_uploads_avatar_post */
export interface BodyUploadAvatarApiUploadsAvatarPost {
  /** File */
  file?: File | string | null;
}

/** Body_upload_event_file_api_uploads_event_files__event_id__post */
export interface BodyUploadEventFileApiUploadsEventFilesEventIdPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_file_api_uploads_generic__source___instance_id__post */
export interface BodyUploadFileApiUploadsGenericSourceInstanceIdPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** ContactRequestCreateDTO */
export interface ContactRequestCreateDTO {
  /** Text */
  text: string;
  /** Organization Ref */
  organization_ref: number;
}

/** ContactRequestListResponseDTO */
export interface ContactRequestListResponseDTO {
  /** Id */
  id: number;
  /** Text */
  text: string;
  /** User Ref */
  user_ref: number;
  /** Organization Ref */
  organization_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** ContactRequestResponseDTO */
export interface ContactRequestResponseDTO {
  /** Id */
  id: number;
  /** Text */
  text: string;
  /** User Ref */
  user_ref: number;
  /** Organization Ref */
  organization_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventFAQCreateDTO */
export interface EventFAQCreateDTO {
  /** Title */
  title: string;
  /** Text */
  text: string;
  /** Index */
  index: number;
  /** Event Ref */
  event_ref: number;
}

/** EventFAQListResponseDTO */
export interface EventFAQListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Text */
  text: string;
  /** Index */
  index: number;
  /** Event Ref */
  event_ref: number;
}

/** EventFAQResponseDTO */
export interface EventFAQResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Text */
  text: string;
  /** Index */
  index: number;
  /** Event Ref */
  event_ref: number;
}

/** EventFAQUpdateDTO */
export interface EventFAQUpdateDTO {
  /** Title */
  title: string;
  /** Text */
  text: string;
  /** Index */
  index: number;
  /** Event Ref */
  event_ref: number;
}

/** EventFileType */
export enum EventFileType {
  Image = "image",
  Video = "video",
  File = "file",
}

/** EventLinkCreateDTO */
export interface EventLinkCreateDTO {
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
}

/** EventLinkListResponseDTO */
export interface EventLinkListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventLinkResponseDTO */
export interface EventLinkResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventLinkUpdateDTO */
export interface EventLinkUpdateDTO {
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
}

/** EventLinksListResponseDTO */
export interface EventLinksListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * In Wishlist
   * @default false
   */
  in_wishlist?: boolean;
}

/** EventLinksResponseDTO */
export interface EventLinksResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Link */
  link: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * In Wishlist
   * @default false
   */
  in_wishlist?: boolean;
}

/** EventRegistrationCreateDTO */
export interface EventRegistrationCreateDTO {
  /** Event Ref */
  event_ref: number;
  /** User Ref */
  user_ref?: number | null;
  status?: EventRegistrationStatus | null;
}

/** EventRegistrationListResponseDTO */
export interface EventRegistrationListResponseDTO {
  /** Id */
  id: string;
  /** Event Ref */
  event_ref: number;
  /** User Ref */
  user_ref?: number | null;
  status: EventRegistrationStatus;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventRegistrationStatus */
export enum EventRegistrationStatus {
  InvitedByUser = "invited_by_user",
  NotUserConfirmed = "not_user_confirmed",
  PendingAdminApprove = "pending_admin_approve",
  PendingCheckIn = "pending_check_in",
  CheckInPassed = "check_in_passed",
  Canceled = "canceled",
  Rejected = "rejected",
}

/** EventRegistrationStatusNotificationDTO */
export interface EventRegistrationStatusNotificationDTO {
  /** Event Ref */
  event_ref: number;
  /**
   * Status List
   * @default []
   */
  status_list?: EventRegistrationStatus[];
}

/** EventRegistrationUpdateDTO */
export interface EventRegistrationUpdateDTO {
  status: EventRegistrationStatus;
}

/** EventStatus */
export enum EventStatus {
  Pending = "pending",
  InProgress = "in_progress",
  Finished = "finished",
}

/** EventType */
export enum EventType {
  Public = "public",
  Private = "private",
  Linked = "linked",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** NotificationsListResponseDTO */
export interface NotificationsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** File */
  file?: string | null;
  /** Text */
  text: string;
  /**
   * Schedule Datetime
   * @format date-time
   */
  schedule_datetime: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Task Id */
  task_id?: string | null;
  /** Status */
  status?: string | null;
  /**
   * User Status List
   * @default []
   */
  user_status_list?: UserStatus[];
}

/** NotificationsResponseDTO */
export interface NotificationsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Text */
  text: string;
  /** File */
  file?: string | null;
  /**
   * Schedule Datetime
   * @format date-time
   */
  schedule_datetime: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * User Refs
   * @default []
   */
  user_refs?: number[];
  /** Task Id */
  task_id?: string | null;
  /** Status */
  status?: string | null;
  /**
   * User Status List
   * @default []
   */
  user_status_list?: UserStatus[];
  /**
   * Event Registration Status Notification List
   * @default []
   */
  event_registration_status_notification_list?: EventRegistrationStatusNotificationDTO[];
}

/** OrganizationsListResponseDTO */
export interface OrganizationsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Mail */
  mail?: string | null;
  /** Url */
  url?: string | null;
  /** Logo */
  logo?: string | null;
  /** Description */
  description: string;
  /** Socials */
  socials?: object[] | string | null;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Index */
  index: number;
}

/** ShortEventFileDTO */
export interface ShortEventFileDTO {
  /** Id */
  id: number;
  /** Filename */
  filename: string;
  /** In Wishlist */
  in_wishlist: boolean;
}

/** SourceType */
export type SourceType = any;

/** TagGroupsCreateDTO */
export interface TagGroupsCreateDTO {
  /** Title */
  title: string;
}

/** TagGroupsListResponseDTO */
export interface TagGroupsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** TagGroupsResponseDTO */
export interface TagGroupsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** TagGroupsUpdateDTO */
export interface TagGroupsUpdateDTO {
  /** Title */
  title: string;
}

/** TagsCreateDTO */
export interface TagsCreateDTO {
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
}

/** TagsResponseDTO */
export interface TagsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** TagsUpdateDTO */
export interface TagsUpdateDTO {
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
}

/** UserEventFeedbackCreateDTO */
export interface UserEventFeedbackCreateDTO {
  /**
   * Comment
   * @maxLength 300
   */
  comment: string;
  /**
   * Rating
   * @min 0
   * @max 4
   */
  rating: number;
}

/** UserInterestDTO */
export interface UserInterestDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
}

/** UserRegistrationDTO */
export interface UserRegistrationDTO {
  /** Fullname */
  fullname: string;
  /** Company */
  company: string;
  /** Position */
  position: string;
  /**
   * Phone Number
   * @pattern ^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{2})[-. ]*(\d{2})(?: *x(\d+))?\s*$
   */
  phone_number: string;
  /** Email */
  email: string;
  /** Birthday */
  birthday?: string | null;
  /**
   * Load Telegram Avatar
   * @default false
   */
  load_telegram_avatar?: boolean;
  /**
   * Tag Refs List
   * @default []
   */
  tag_refs_list?: number[];
  /** Invite Ref */
  invite_ref?: string | null;
}

/** UserRegistrationInviteCreateDTO */
export interface UserRegistrationInviteCreateDTO {
  /** @default {} */
  payload?: UserRegistrationInvitePayloadDTO;
}

/** UserRegistrationInviteListResponseDTO */
export interface UserRegistrationInviteListResponseDTO {
  /** Id */
  id: string;
  /** Creator Ref */
  creator_ref?: number | null;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Referral Link
   * @default ""
   */
  referral_link?: string;
}

/** UserRegistrationInvitePayloadDTO */
export interface UserRegistrationInvitePayloadDTO {
  /** Fullname */
  fullname?: string | null;
  /** Company */
  company?: string | null;
  /** Position */
  position?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  /** Birthday */
  birthday?: string | null;
}

/** UserRegistrationInviteUpdateDTO */
export interface UserRegistrationInviteUpdateDTO {
  /** @default {} */
  payload?: UserRegistrationInvitePayloadDTO;
}

/** UserResponseDTO */
export interface UserResponseDTO {
  /** Id */
  id: number;
  status: UserStatus;
  /** Fullname */
  fullname?: string | null;
  /** Company */
  company?: string | null;
  /** Position */
  position?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  /** Birthday */
  birthday?: string | null;
  /** Avatar */
  avatar?: string | null;
  telegram_profile: ServerApiUsersClientDtoTelegramProfile;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Interests
   * @default []
   */
  interests?: UserInterestDTO[];
}

/** UserStatus */
export enum UserStatus {
  NOT_AUTH = "NOT_AUTH",
  NOT_APPROVED = "NOT_APPROVED",
  APPROVED = "APPROVED",
  BLOCKED = "BLOCKED",
}

/** UserWishListResponseDTO */
export interface UserWishListResponseDTO {
  /** Event Ref */
  event_ref: number;
}

/** UsersListResponseDTO */
export interface UsersListResponseDTO {
  /** Id */
  id: number;
  status: UserStatus;
  /** Fullname */
  fullname?: string | null;
  /** Email */
  email?: string | null;
  telegram_profile: ServerApiUsersAdminDtoTelegramProfile;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** UsersResponseDTO */
export interface UsersResponseDTO {
  /** Id */
  id: number;
  status: UserStatus;
  /** Fullname */
  fullname?: string | null;
  /** Company */
  company?: string | null;
  /** Position */
  position?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  /** Birthday */
  birthday?: string | null;
  /** Avatar */
  avatar?: string | null;
  telegram_profile: ServerApiUsersAdminDtoTelegramProfile;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Invite Ref */
  invite_ref?: string | null;
}

/** UsersUpdateDTO */
export interface UsersUpdateDTO {
  status: UserStatus;
  /** Fullname */
  fullname?: string | null;
  /** Company */
  company?: string | null;
  /** Position */
  position?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  /** Birthday */
  birthday?: string | null;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /** Invite Ref */
  invite_ref?: string | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** WishlistedEventFilesResponseDTO */
export interface WishlistedEventFilesResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /**
   * Files
   * @default []
   */
  files?: ShortEventFileDTO[];
}

/** EventsListResponseDTO */
export interface ServerApiEventsAdminDtoEventsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: string | null;
  /** Square Banner */
  square_banner?: string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Location */
  location: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Total Visitors
   * @default 0
   */
  total_visitors?: number | null;
  /**
   * Total Registrations
   * @default 0
   */
  total_registrations?: number | null;
  /** Average Rating */
  average_rating: number | null;
  /**
   * Convertion Rate
   * @default 0
   */
  convertion_rate?: number;
  /** Help Url */
  help_url?: string | null;
}

/** EventsResponseDTO */
export interface ServerApiEventsAdminDtoEventsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: string | null;
  /** Square Banner */
  square_banner?: string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Location */
  location: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /**
   * Tags Ref List
   * @default []
   */
  tags_ref_list?: number[];
  /**
   * Total Visitors
   * @default 0
   */
  total_visitors?: number | null;
  /**
   * Total Registrations
   * @default 0
   */
  total_registrations?: number | null;
  /** Average Rating */
  average_rating: number | null;
  /** Help Url */
  help_url?: string | null;
  /**
   * Convertion Rate
   * @default 0
   */
  convertion_rate?: number;
}

/** UserEventFeedbackResponseDTO */
export interface ServerApiEventsAdminDtoUserEventFeedbackResponseDTO {
  /** Id */
  id: number;
  /** Comment */
  comment: string;
  /** Rating */
  rating: number;
  /** User Ref */
  user_ref: number;
  /** Event Ref */
  event_ref: number;
}

/** EventsListResponseDTO */
export interface ServerApiEventsClientDtoEventsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: string | null;
  /** Square Banner */
  square_banner?: string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Location */
  location: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  user_registration_status?: EventRegistrationStatus | null;
  /** @default "pending" */
  event_status?: EventStatus;
  /** Help Url */
  help_url?: string | null;
}

/** EventsResponseDTO */
export interface ServerApiEventsClientDtoEventsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Description */
  description: string;
  type: EventType;
  /** Preview */
  preview?: string | null;
  /** Square Banner */
  square_banner?: string | null;
  /**
   * Start At
   * @format date-time
   */
  start_at: string;
  /**
   * End At
   * @format date-time
   */
  end_at: string;
  /** Location */
  location: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Organizations Ref List
   * @default []
   */
  organizations_ref_list?: number[];
  /**
   * Tags Ref List
   * @default []
   */
  tags_ref_list?: number[];
  user_registration_status?: EventRegistrationStatus | null;
  /** @default "pending" */
  event_status?: EventStatus;
  /**
   * Event Files Count
   * @default 0
   */
  event_files_count?: number;
  /** Help Url */
  help_url?: string | null;
}

/** UserEventFeedbackResponseDTO */
export interface ServerApiEventsClientDtoUserEventFeedbackResponseDTO {
  /** Id */
  id: number;
  /**
   * Comment
   * @maxLength 300
   */
  comment: string;
  /**
   * Rating
   * @min 0
   * @max 4
   */
  rating: number;
}

/** EventFilesListResponseDTO */
export interface ServerApiEventsEventFilesAdminDtoEventFilesListResponseDTO {
  /** Id */
  id: number;
  /** File */
  file: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventFilesResponseDTO */
export interface ServerApiEventsEventFilesAdminDtoEventFilesResponseDTO {
  /** Id */
  id: number;
  /** File */
  file: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** EventFilesListResponseDTO */
export interface ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO {
  /** Id */
  id: number;
  /** File */
  file: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * In Wishlist
   * @default false
   */
  in_wishlist?: boolean;
}

/** EventFilesResponseDTO */
export interface ServerApiEventsEventFilesClientDtoEventFilesResponseDTO {
  /** Id */
  id: number;
  /** File */
  file: string;
  /** Event Ref */
  event_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * In Wishlist
   * @default false
   */
  in_wishlist?: boolean;
}

/** EventRegistrationResponseDTO */
export interface ServerApiEventsRegistrationAdminDtoEventRegistrationResponseDTO {
  /** Id */
  id: string;
  /** Event Ref */
  event_ref: number;
  /** User Ref */
  user_ref?: number | null;
  status: EventRegistrationStatus;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Invite Link
   * @default ""
   */
  invite_link?: string;
}

/** EventRegistrationResponseDTO */
export interface ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO {
  /** Ticket Id */
  ticket_id: string;
  /** Event Ref */
  event_ref: number;
  /** User Ref */
  user_ref: number;
  status: EventRegistrationStatus;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** OrganizationsResponseDTO */
export interface ServerApiOrganizationsAdminDtoOrganizationsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Mail */
  mail?: string | null;
  /** Url */
  url?: string | null;
  /** Logo */
  logo?: string | null;
  /** Telegram Chat Id */
  telegram_chat_id: number;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Socials */
  socials?: string | null;
  /** Index */
  index: number;
}

/** OrganizationsResponseDTO */
export interface ServerApiOrganizationsClientDtoOrganizationsResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Mail */
  mail?: string | null;
  /** Url */
  url?: string | null;
  /** Logo */
  logo?: string | null;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Socials */
  socials?: object[] | string | null;
  /** Index */
  index: number;
}

/** TagsListResponseDTO */
export interface ServerApiTagsAdminDtoTagsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** TagsListResponseDTO */
export interface ServerApiTagsClientDtoTagsListResponseDTO {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** Group Ref */
  group_ref: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Group Title */
  group_title: string;
}

/** TelegramProfile */
export interface ServerApiUsersAdminDtoTelegramProfile {
  /** User Id */
  user_id: number;
  /** Username */
  username?: string | null;
}

/** TelegramProfile */
export interface ServerApiUsersClientDtoTelegramProfile {
  /** User Id */
  user_id: number;
  /** Username */
  username: string | null;
}

/** UserRegistrationInviteResponseDTO */
export interface ServerApiUsersInvitesAdminDtoUserRegistrationInviteResponseDTO {
  /** Id */
  id: string;
  /** Creator Ref */
  creator_ref?: number | null;
  /** @default {} */
  payload?: UserRegistrationInvitePayloadDTO;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Referral Link
   * @default ""
   */
  referral_link?: string;
}

/** UserRegistrationInviteResponseDTO */
export interface ServerApiUsersInvitesClientDtoUserRegistrationInviteResponseDTO {
  /** Id */
  id: string;
  /** Creator Ref */
  creator_ref?: number | null;
  /** @default {} */
  payload?: UserRegistrationInvitePayloadDTO;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}
