import React, { useState } from 'react';
import './index.scss';
import EventRegistrationImage from '@/widgets/events/EventRegistrationImage/EventRegistrationImage';
import mockBlueprint from 'public/images/mock_blueprint.png';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import EventRegistrationCard from '@/widgets/events/EventRegistrationCard/EventRegistrationCard';
import DateChip from '@/shared/ui/DateChip/DateChip';
import Background from '@/shared/ui/background/Background';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import communication from 'public/images/communication.png';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '@/shared/hooks/useFetch';
import {
	ServerApiEventsClientDtoEventsResponseDTO,
	ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO,
	ServerApiTagsClientDtoTagsListResponseDTO,
} from '@/shared/api/gen/data-contracts';
import api from '@/shared/api/api';
import calendarPlus from 'public/images/Calendar_plus.png';
import qrWhite from 'public/images/QRWhite.png';
import EventTags from '@/shared/ui/EventTags/EventTags';
import RichText from '@/shared/ui/RichText/RichText';
import { getShortText } from '@/shared/utils/HOC/getShortText';
import { AnimatePresence } from 'framer-motion';
import TicketModal from '@/widgets/events/TicketModal/TicketModal';
import InviteModal from '@/widgets/InviteModal/InviteModal';
import InviteReferral from '@/shared/ui/InviteReferral/InviteReferal';
import useTG from '@/shared/hooks/useTG';
import ArrowButton from '@/shared/ui/ArrowButton/ArrowButton';
import FeedbackModal, { Emoji } from '@/widgets/events/FeedbackModal/FeedbackModal';
import { FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import folder from 'public/images/Folder.png';
import star from 'public/images/Star.png';
import BookmarkImage from '@/widgets/BookmarkImage/BookmarkImage';
import { EnvConfig } from '@/app/config';

const EventPage = () => {
	const { id } = useParams<'id'>();
	const navigate = useNavigate();
	const eventId = id as unknown as number;
	const { WebApp } = useTG({ requestWriteAccess: true });
	const [isTicketOpen, setIsTicketOpen] = useState<boolean>(false);
	const [isInviteOpen, setIsInviteOpen] = useState<boolean>(false);
	const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);
	const { data: event, error: EventError } = useFetch<ServerApiEventsClientDtoEventsResponseDTO>(
		() => api.events.getEventsDetailApiEventsIdGet(id as unknown as number),
		// .catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`)),
		{} as ServerApiEventsClientDtoEventsResponseDTO,
		[]
	);
	const { data: tags } = useFetch<ServerApiTagsClientDtoTagsListResponseDTO[]>(
		() => api.tags.getTagsApiTagsGet(),
		[],
		[]
	);
	const {
		data: eventRegistration,
		refresh,
		error: eventRegistrationError,
		loading: eventLoading,
	} = useFetch<ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO>(
		() => api.events.getEventRegistrationDetailApiEventsRegistrationEventIdGet(eventId),
		{},
		[]
	);
	const register = () => {
		api.events
			.registerOnEventApiEventsRegistrationEventEventIdPut(eventId)
			.catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`))
			.then(() => refresh());
	};
	const addToCalendar = () => {
		api.events
			.getIcsHashApiEventsEventIdIcsHashGet(eventId)
			.catch((error) => alert(`Произошла ошибка со статус кодом ${error.status} при получении ics хэша`))
			.then((res) => {
				api.events
					.getIcsFileApiEventsIcsHashIcsGet(res.data)
					.catch((error) =>
						alert(`Произошла ошибка со статус кодом ${error.status} при получении файла из хэша`)
					)
					.then((res) => {
						WebApp.downloadFile({ url: `/${res.data}`, file_name: 'event.ics' });
					});
			});
	};
	const startDate = new Date(event.start_at);
	const endDate = new Date(event.end_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		hour: '2-digit',
		minute: '2-digit',
	};
	const isUserRegistered =
		eventRegistration?.status == 'check_in_passed' || eventRegistration?.status == 'pending_check_in';
	const eventTags = tags.filter((tag) => event?.tags_ref_list?.includes(tag.id)).map((tag) => tag.title);
	if (eventLoading) return null;
	return (
		<PageLayout
			background
			backgroundStyles={{ background: '#F5F8FB' }}>
			<div className='EventRegistration invisibleScroll'>
				{event?.id && (
					<BookmarkImage
						id={event?.id}
						image={event?.square_banner || event?.preview || ''}
					/>
				)}
				{isUserRegistered && (
					<CustomButton
						theme={ButtonVariant.orange}
						className='br-12 g-12'
						onClick={addToCalendar}>
						<img
							src={calendarPlus}
							alt='calendar'
							style={{ width: '24px', height: '24px' }}
						/>
						<span className='text-18 medium'>Добавить в календарь</span>
					</CustomButton>
				)}
				<FlexRow className='g-8'>
					{!!event?.event_files_count && (
						<CustomButton
							style={{
								width: '100%',
								flexDirection: 'column',
								alignItems: 'flex-start',
								padding: '10px 16px',
								borderRadius: '9px',
								gap: '8px',
								textAlign: 'start',
							}}
							theme={ButtonVariant.orange}
							onClick={() => navigate(`/events/${eventId}/materials`)}>
							<img
								src={folder}
								alt='folder'
								style={{ width: '24px', height: '24px' }}
							/>
							<span className='text-16 medium'>
								Материалы <br />
								мероприятия
							</span>
						</CustomButton>
					)}
					<CustomButton
						style={{
							width: '100%',
							flexDirection: 'column',
							alignItems: 'flex-start',
							padding: '10px 16px',
							borderRadius: '9px',
							gap: '8px',
							textAlign: 'start',
						}}
						onClick={() => {
							setIsFeedbackOpen(true);
						}}
						theme={ButtonVariant.orange}>
						<img
							src={star}
							alt='star'
							style={{ width: '24px', height: '24px' }}
						/>
						<span className='text-16 medium'>
							Оценить <br />
							мероприятие
						</span>
					</CustomButton>
				</FlexRow>
				<EventRegistrationCard>
					<DateChip>
						<span className='text-16 medium'>{startDate.toLocaleDateString('ru-RU', dateOptions)}</span>
						{startDate.getDate() !== endDate.getDate() && (
							<span className='text-16 medium'>
								{' '}
								- {endDate.toLocaleDateString('ru-RU', dateOptions)}
							</span>
						)}
					</DateChip>
					<h1 className='text-22 medium mt-12 mb-12'>{event?.title}</h1>
					<h2 className='text-16 regular mt-8'>
						{event?.description && getShortText(event?.description, { maxLength: 100 }).text}
					</h2>
				</EventRegistrationCard>
				<EventRegistrationCard>
					<RichText text={event?.location} />
					<img
						onClick={() => window.open(mockBlueprint)}
						className='mt-12'
						src={mockBlueprint}
						alt='place_image'
					/>
				</EventRegistrationCard>
				<EventRegistrationCard>
					<h1 className='text-22 medium mb-12'>Описание события</h1>
					<RichText text={event?.description} />
				</EventRegistrationCard>
				{/* {isUserRegistered && (
					<EventRegistrationCard>
						<h1 className='text-22 medium'>Пригласить друга</h1>
						<InviteReferral
							setVisible={setIsInviteOpen}
							link='https://realweb.ru/contacts'
							className='mt-12'
						/>
					</EventRegistrationCard>
				)} */}
				{!!eventTags && eventTags?.length > 0 && (
					<EventRegistrationCard>
						<EventTags tags={eventTags} />
					</EventRegistrationCard>
				)}
				<ArrowButton
					text='Часто задаваемые вопросы'
					icon={communication}
					onClick={() => navigate(`/events/${id}/support`)}
				/>
				<div className='EventRegistration_register'>
					{isUserRegistered && eventRegistration?.ticket_id ? (
						<CustomButton
							onClick={() => setIsTicketOpen(true)}
							className='g-12'>
							<img
								src={qrWhite}
								alt='qr'
								style={{ width: '24px', height: '24px' }}
							/>
							<span className='text-18 medium'>Открыть билет</span>
						</CustomButton>
					) : (
						<CustomButton onClick={register}>
							<span className='text-18 medium'>Зарегистрироваться</span>
						</CustomButton>
					)}
				</div>
				<AnimatePresence>
					{isTicketOpen && eventRegistration?.ticket_id && (
						<TicketModal
							event={event}
							setVisible={setIsTicketOpen}
							link={`${EnvConfig.ADMIN_HOST_URL}/check_in/${eventRegistration?.ticket_id}`}
						/>
					)}
					{/* {isInviteOpen && (
						<InviteModal
							setVisible={setIsInviteOpen}
							link='invite link here'
						/>
					)} */}
					{isFeedbackOpen && (
						<FeedbackModal
							setVisible={setIsFeedbackOpen}
							eventId={eventId}
						/>
					)}
				</AnimatePresence>
			</div>
		</PageLayout>
	);
};

export default EventPage;
