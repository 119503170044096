import { FlexColumn, FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import React from 'react';
import errorBullet from 'public/images/ErrorBullet.png';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';

interface Props {}

function ErrorPage({ errorMessage }: { errorMessage?: string }) {
	return (
		// <PageLayout background>
		<FlexColumn
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				height: '100svh',
				gap: '35px',
				padding: '16px',
			}}>
			<FlexRow style={{ width: '100%', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
				<img
					className='errorBullet'
					style={{ width: '67px', height: '194px' }}
					src={errorBullet}
					alt=''
				/>
			</FlexRow>
			<h1 className='text-22 regular'>Что-то пошло не так...</h1>
			<p className='text-18'>{errorMessage}</p>
			<CustomButton theme={ButtonVariant.grey}>
				<p className='text-18'>Связаться с поддержкой</p>
			</CustomButton>
			<CustomButton theme={ButtonVariant.grey}>
				<p className='text-18'>На главную</p>
			</CustomButton>
		</FlexColumn>
		// </PageLayout>
	);
}

export default ErrorPage;
