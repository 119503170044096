import { EnvConfig } from '@/app/config';
import { ApiConfig } from '@/shared/api/gen/http-client';
import { Url } from '@/shared/api/gen/Url';
import { Tags } from '@/shared/api/gen/Tags';
import { Users } from '@/shared/api/gen/Users';
import { Uploads } from '@/shared/api/gen/Uploads';
import { Events } from '@/shared/api/gen/Events';
import { TagGroups } from '@/shared/api/gen/TagGroups';
import { Organizations } from './gen/Organizations';
import { ContactRequests } from './gen/ContactRequests';
import { EventFaq } from './gen/EventFaq';

const apiStore = (apiConfig?: ApiConfig<unknown>) => ({
	tags: new Tags(apiConfig),
	users: new Users(apiConfig),
	uploads: new Uploads(apiConfig),
	events: new Events(apiConfig),
	tagGroups: new TagGroups(apiConfig),
	organizations: new Organizations(apiConfig),
	contactRequests: new ContactRequests(apiConfig),
	eventFaq: new EventFaq(apiConfig),
});

const api = apiStore({
	baseUrl: EnvConfig.HOST_URL,
});

export default api;
