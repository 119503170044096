import React, { SetStateAction, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.scss';
import { motion } from 'framer-motion';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import calendarPrev from 'public/images/CalendarPrev.png';
import calendarNext from 'public/images/CalendarNext.png';
import BackgroundAnimation from '@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation';
import api from '@/shared/api/api';
import useFetch from '@/shared/hooks/useFetch';

type Props = {
	setVisible: React.Dispatch<SetStateAction<boolean>>;
	setFilterDates: React.Dispatch<SetStateAction<any[]>>;
	filterDates: number[];
};
const CustomCalendar = ({ setVisible, setFilterDates, filterDates }: Props) => {
	const [currMonthDate, setCurrMonthDate] = useState<Date>(new Date(Date.now()));
	const ref = useRef<HTMLDivElement>(null);
	const { data: eventDates } = useFetch(
		() =>
			api.events.getMonthEventsCalendarApiEventsMonthEventsCalendarGet({
				year_month: currMonthDate.toLocaleDateString('fr-CA'),
			}),
		[],
		[currMonthDate]
	);
	const [selectedDates, setSelectedDates] = useState<number[]>(filterDates);
	const handleDayClick = (date: Date) => {
		if (selectedDates.length < 2) {
			setSelectedDates((prevDates) => [...selectedDates, date.getTime()]);
		} else {
			setSelectedDates((prevDates) => [date.getTime()]);
		}
	};
	const getRangeDate = (dates: number[]) => {
		if (dates.length == 0) return [null, null];
		if (dates.length == 1) return [Math.min.apply(Math, dates), Math.min.apply(Math, dates)];
		if (dates.length >= 2) return [Math.min.apply(Math, dates), Math.max.apply(Math, dates)];
	};
	const tileClassName = ({ date }) => {
		let result = [];
		if (
			date.getTime() >= Math.min.apply(Math, selectedDates) &&
			date.getTime() <= Math.max.apply(Math, selectedDates)
		) {
			result.push('CustomCalendar_selectedDay');
		}
		if (eventDates.includes(date.toLocaleDateString('fr-CA'))) {
			result.push('CustomCalendar_fullEventDay');
		}
		return result.join(' ');
	};
	const handleSave = () => {
		const min = Math.min.apply(Math, selectedDates);
		const max = Math.max.apply(Math, selectedDates);
		if (selectedDates.length == 0) setFilterDates([]);
		if (selectedDates.length == 1) setFilterDates([min, min]);
		if (selectedDates.length >= 2) setFilterDates([min, max]);
		setVisible(false);
	};
	React.useEffect(() => {
		console.log('Reference', ref);
	}, [ref]);

	return (
		<>
			<BackgroundAnimation setVisible={setVisible} />
			<motion.div
				style={{
					display: 'flex',
					position: 'fixed',
					left: '0px',
					bottom: '0px',
					width: '100%',
					minHeight: '30svh',
					zIndex: 15,
				}}
				initial={{ y: '100svh' }}
				animate={{ y: '0svh' }}
				exit={{ y: '100svh' }}
				transition={{ ease: 'easeInOut', duration: 0.5 }}>
				<div className='CustomCalendar'>
					<Calendar
						inputRef={ref}
						next2Label={null}
						prev2Label={null}
						prevLabel={
							<img
								src={calendarPrev}
								alt='prev'
								style={{ width: '32px', height: '32px' }}
							/>
						}
						nextLabel={
							<img
								src={calendarNext}
								alt='prev'
								style={{ width: '32px', height: '32px' }}
							/>
						}
						onActiveStartDateChange={({ activeStartDate }) =>
							activeStartDate && setCurrMonthDate(activeStartDate)
						}
						onClickDay={handleDayClick}
						tileClassName={tileClassName}
					/>
					<div className='CustomCalendar_range mt-24'>
						{getRangeDate(selectedDates)?.map((day, index) => {
							return (
								<h1 className='text-18 medium'>
									{index == 0 ? 'Начало' : 'Конец'}{' '}
									<span>{selectedDates.length != 0 ? new Date(day).toLocaleDateString() : null}</span>
								</h1>
							);
						})}
					</div>
					<div className='CustomCalendar_buttons mt-24'>
						<CustomButton
							theme={ButtonVariant.reverse}
							onClick={() => setSelectedDates([])}>
							<span className='text-18 medium'>Сбросить</span>
						</CustomButton>
						<CustomButton onClick={handleSave}>
							<span className='text-18 medium'>Применить</span>
						</CustomButton>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default CustomCalendar;
