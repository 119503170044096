import React, { useState } from 'react';
import './index.scss';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import { FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import useFetch from '@/shared/hooks/useFetch';
import api from '@/shared/api/api';
import {
	EventFileType,
	EventLinksListResponseDTO,
	ServerApiEventsClientDtoEventsListResponseDTO,
	ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO,
	WishlistedEventFilesResponseDTO,
} from '@/shared/api/gen/data-contracts';
import EventCardWithDescription from '@/widgets/events/EventCardWithDescription/EventCardWithDescription';
import PageLayout from '@/shared/ui/layouts/PageLayout';
// import PhotoGrid from '@/shared/ui/PhotoGrid/PhotoGrid';
import Tab, { TabVariant } from '@/shared/ui/Tab/Tab';
import { MaterialsTabs } from '@/pages/events/EventMaterials';
import FileMaterial from '@/shared/ui/eventMaterials/FileMaterial/FileMaterial';
import LinkMaterial from '@/shared/ui/eventMaterials/LinkMaterial/LinkMaterial';
import PhotoPage from '@/pages/PhotoPage';
import arrowUp from 'public/images/ArrowUp.png';
import TempPhotoMaterial from '@/shared/ui/eventMaterials/TempPhotoMaterial/TempPhotoMaterial';
import PhotosGrid from '@/shared/ui/PhotosGrid/PhotosGrid';
import PhotosGridNavigate from '@/shared/ui/PhotosGridNavigate/PhotosGridNavigate';
import torus from 'public/images/Torus.png';
import Tabs from '@/shared/ui/Tabs/Tabs';

const enum FavouritesPageCategory {
	EVENTS = 'События',
	MATERIALS = 'Материалы',
}
const EmptyState = () => {
	return (
		<div className='FavouritesPage_empty'>
			<img
				src={torus}
				alt='torus'
			/>
			<h1 className='text-28 medium mt-16'>В избранном пусто</h1>
			<h2 className='text-18 medium mt-12'>
				Сохраняйте сюда мероприятия, которые вы бы хотели посетить и материалы прошедших событий
			</h2>
		</div>
	);
};
const FavouritesPage = () => {
	const [active, setActive] = useState<FavouritesPageCategory>(FavouritesPageCategory.EVENTS);
	const [activeTab, setActiveTab] = useState<MaterialsTabs>(MaterialsTabs.PHOTO);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isGalleryActive, setIsGalleryActive] = useState<boolean>(false);
	const { data: events } = useFetch<ServerApiEventsClientDtoEventsListResponseDTO[]>(
		() => api.events.getEventsApiEventsGet({ in_wishlist: true }),
		[],
		[]
	);
	const { data: photos, refresh: photosRefresh } = useFetch<WishlistedEventFilesResponseDTO[]>(
		() => api.events.getWishlistedEventFilesApiEventsEventFilesWishlistedGet({ file_type: EventFileType.Image }),
		[],
		[]
	);
	const { data: files, refresh: filesRefresh } = useFetch<WishlistedEventFilesResponseDTO[]>(
		() => api.events.getWishlistedEventFilesApiEventsEventFilesWishlistedGet({ file_type: EventFileType.File }),
		[],
		[]
	);
	const { data: links, refresh: linksRefresh } = useFetch<EventLinksListResponseDTO[]>(
		() => api.events.getEventLinksApiEventsEventLinksGet({ event_ref: 1 }),
		[],
		[]
	);
	const handlePhotoClick = (index: number) => {
		setIsGalleryActive(true);
		setCurrentIndex(index);
	};
	const isEmptyContent =
		(events?.length || 0) + (photos?.length || 0) + (files?.length || 0) + (links?.length || 0) <= 0;
	return isGalleryActive ? (
		<PhotoPage
			photos={photos}
			currentIndex={currentIndex}
			setCurrentIndex={setCurrentIndex}
		/>
	) : (
		<PageLayout navbar>
			<div className='FavouritesPage'>
				<h1 className='text-22 medium mb-16'>Избранное</h1>
				<Tabs
					name='name'
					option1='События'
					option2='Материалы'
					onChange={(value) => {
						if (value == 'option1') {
							setActive(FavouritesPageCategory.EVENTS);
						} else {
							setActive(FavouritesPageCategory.MATERIALS);
						}
					}}
				/>
				{active == FavouritesPageCategory.EVENTS && (
					<>
						<div className='FavouritesPage_events mt-16'>
							{events.map((event) => (
								<EventCardWithDescription
									event={event}
									dateChipClassName='FavouritesPage_dateChip'
								/>
							))}
						</div>
						{active == FavouritesPageCategory.EVENTS && events?.length <= 0 && <EmptyState />}
					</>
				)}
				{active == FavouritesPageCategory.MATERIALS && (
					<FlexRow style={{ gap: '4px', marginTop: '32px', justifyContent: 'flex-start' }}>
						{photos?.length > 0 && (
							<Tab
								text={MaterialsTabs.PHOTO}
								theme={MaterialsTabs.PHOTO == activeTab ? TabVariant.active : TabVariant.default}
								setActive={setActiveTab}
							/>
						)}
						{files?.length > 0 && (
							<Tab
								text={MaterialsTabs.FILES}
								theme={MaterialsTabs.FILES == activeTab ? TabVariant.active : TabVariant.default}
								setActive={setActiveTab}
							/>
						)}
						{links?.length > 0 && (
							<Tab
								text={MaterialsTabs.LINKS}
								theme={MaterialsTabs.LINKS == activeTab ? TabVariant.active : TabVariant.default}
								setActive={setActiveTab}
							/>
						)}
					</FlexRow>
				)}
				{active == FavouritesPageCategory.MATERIALS &&
					links?.length <= 0 &&
					files?.length <= 0 &&
					photos?.length <= 0 && <EmptyState />}
				{active == FavouritesPageCategory.MATERIALS && activeTab == MaterialsTabs.PHOTO && (
					<>
						{photos?.map((photoGroup, index) => (
							<>
								<PhotosGridNavigate photoGroup={photoGroup} />
								<PhotosGrid
									photos={photoGroup.files}
									handleClick={handlePhotoClick}
									refresh={photosRefresh}
								/>
							</>
						))}
					</>
				)}
				{active == FavouritesPageCategory.MATERIALS && activeTab == MaterialsTabs.FILES && (
					<div className='EventsMaterials_files mt-16'>
						{files.map((file) => (
							<FileMaterial
								file={file}
								refresh={filesRefresh}
							/>
						))}
					</div>
				)}
				{active == FavouritesPageCategory.MATERIALS && activeTab == MaterialsTabs.LINKS && (
					<div className='EventsMaterials_links mt-16'>
						{links?.map((link) => (
							<LinkMaterial
								link={link}
								refresh={linksRefresh}
							/>
						))}
					</div>
				)}
			</div>
		</PageLayout>
	);
};

export default FavouritesPage;
