import React from "react";
import './index.scss';

const DateChip = ({children, ...props}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={`DateChip ${props?.className}`}
            style={props?.style}
        >
            {children}
        </div>
    )
}

export default DateChip;