import React from 'react';
import './index.scss';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import tgLogo from 'public/images/TelegramLogo.png';
import DropdownList from '@/shared/ui/DropdownList/DropdownList';
import { FlexColumn } from '@/shared/ui/layouts/Flex/Flex';
import { SupportQuestions } from '@/mock/SupportQuestions';
import { EventFAQListResponseDTO } from '@/shared/api/gen/data-contracts';

export const FaqLayout = ({ faqs, supportUrl }: { faqs: EventFAQListResponseDTO[]; supportUrl?: string }) => {
	return (
		<div className='SupportPage invisibleScroll'>
			{supportUrl && (
				<CustomButton
					className='g-12'
					theme={ButtonVariant.reverse}
					style={{ borderRadius: '10px' }}
					onClick={() => window.open(supportUrl, '_blank')}>
					<img
						src={tgLogo}
						alt='logo'
						style={{ width: '24px', height: '24px' }}
					/>
					<span className='text-18 medium'>Написать в Telegram</span>
				</CustomButton>
			)}
			<h1 className='text-22 medium mt-32'>Часто задаваемые вопросы</h1>
			<FlexColumn className='g-8 mt-16'>
				{faqs.map((el) => (
					<DropdownList {...el} />
				))}
			</FlexColumn>
		</div>
	);
};

const SupportPage = () => {
	return (
		<FaqLayout
			faqs={SupportQuestions}
			supportUrl='https://tg.me'
		/>
	);
};

export default SupportPage;
