import React, { useState } from 'react';
import { AnimatePresence, color, motion } from 'framer-motion';
import { FlexColumn, FlexRow } from '@/shared/ui/layouts/Flex/Flex';
import BackgroundAnimation from '@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation';
import Modal from '@/shared/ui/Modal/Modal';
import tgIcon from 'public/icons/TG.svg';
import vkIcon from 'public/icons/VK.svg';
import xIcon from 'public/icons/X.svg';
import youtubeIcon from 'public/icons/YouTube.svg';
import telegramBordericon from 'public/icons/Telegram.svg';
import vectorIcon from 'public/icons/Vector.svg';
import './index.scss';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import { TextAreaInput } from '@/shared/ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { ContactRequestCreateDTO, OrganizationsListResponseDTO } from '@/shared/api/gen/data-contracts';
import RichText from '@/shared/ui/RichText/RichText';
import api from '@/shared/api/api';

interface Props {
	id: number;
	close: () => any;
}

function CompanyOrder({ id, close }: Props) {
	const [success, setSuccess] = useState(false);
	const { register, setFocus, handleSubmit } = useForm<ContactRequestCreateDTO>({
		defaultValues: { organization_ref: id },
	});
	const handleOrder = (data: ContactRequestCreateDTO) => {
		api.contactRequests.createContactRequestApiContactRequestsPost(data).then(() => {
			setSuccess(true);
			setTimeout(() => close(), 2000);
		});
	};
	React.useEffect(() => {
		setFocus('text');
	}, []);
	return (
		<Modal
			close={close}
			style={{ minHeight: '60svh' }}>
			<FlexColumn
				className='g-16'
				style={{ justifyContent: 'flex-start', width: '100%', alignItems: 'initial', flex: '1 1 auto' }}>
				<h1 className='text-18'>Запросить контакт</h1>
				{success && <p className='text-16 light'>Заявка сформирована!</p>}
				<TextAreaInput
					placeholder='Опишите ваш запрос и менеджер организации свяжется с вами'
					field='message'
					register={register('text')}
				/>
				<CustomButton
					style={{ marginTop: 'auto' }}
					onClick={handleSubmit(handleOrder)}>
					<p className='text-18'>Связаться</p>
				</CustomButton>
			</FlexColumn>
		</Modal>
	);
}
interface ICompanyModal {
	organization: OrganizationsListResponseDTO;
	close: () => any;
}
function CompanyModal({ organization, close }: ICompanyModal) {
	// const [isOpen, setOpen] = useState(false);
	const socials = {
		x: xIcon,
		youtube: youtubeIcon,
		vk: vkIcon,
		telegram: tgIcon,
	};
	return (
		<Modal close={close}>
			<FlexColumn
				style={{
					justifyContent: 'flex-start',
					padding: '28px 0px',
					overflowY: 'scroll',
				}}
				className='CompanyModal invisibleScroll g-24'>
				<img
					src={organization?.logo}
					alt=''
				/>
				<FlexColumn
					className='g-8'
					style={{ width: '100%' }}>
					{!!organization?.url && (
						<CustomButton
							onClick={() => window.open(organization?.url, '_blank')}
							style={{ borderRadius: '12px', display: 'flex', gap: '12px' }}>
							<img
								src={vectorIcon}
								alt=''
							/>
							<p className='text-18 medium'>Перейти на сайт</p>
						</CustomButton>
					)}
					{!!organization?.mail && (
						<a
							href={organization?.mail}
							target='_blank'>
							<CustomButton
								theme={ButtonVariant.grey}
								style={{ color: 'black', display: 'flex', gap: '12px' }}>
								<img
									src={telegramBordericon}
									alt=''
								/>
								<p className='text-18 medium'>Связаться</p>
							</CustomButton>
						</a>
					)}
				</FlexColumn>
				<RichText
					text={organization.description}
					style={{ width: '100%' }}
				/>
				<FlexRow
					className='CompanyModal__socials g-12'
					style={{ height: '36px', width: '100%', justifyContent: 'flex-start' }}>
					{organization?.socials?.length > 0 &&
						organization?.socials?.map(
							(el: { id: string; url: string }) =>
								el.id in socials && (
									<img
										onClick={() => window.open(el.url, '_blank')}
										src={socials[el.id]}
										alt='telegramm'
									/>
								)
						)}
				</FlexRow>
				{/* <AnimatePresence>
					{isOpen && (
						<CompanyOrder
							id={organization.id}
							close={() => setOpen(false)}
						/>
					)}
				</AnimatePresence> */}
			</FlexColumn>
		</Modal>
	);
}

export default CompanyModal;
