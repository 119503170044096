import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import reportWebVitals from './reportWebVitals';
import AppRouter from './app/router';
import ErrorPage from './pages/general/ErrorPage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function fallbackRender({ error, resetErrorBoundary }) {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	return <ErrorPage errorMessage={error.message}></ErrorPage>;
}
root.render(
	/*<React.StrictMode>*/
	<ErrorBoundary fallbackRender={fallbackRender}>
		<AppRouter />
	</ErrorBoundary>

	/*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
