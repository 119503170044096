import React from "react";


interface ThemeContextProps {
    isDarkTheme: boolean;
    setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThemeContext = React.createContext<ThemeContextProps>({} as ThemeContextProps);

/**
 * Контекст для управления светлой и темной темами
 * @param param0 
 * @returns 
 */
export default function ThemeContextProvider({ children }: any) {
    const [isDarkTheme, setIsDarkTheme] = React.useState<boolean>(localStorage.getItem("Theme") === "dark");


    const providerInterface = React.useMemo(() => ({
        isDarkTheme, setIsDarkTheme
    }), [isDarkTheme])

    React.useEffect(() => {
        document.documentElement.setAttribute(
            'data-color-scheme',
            isDarkTheme ? 'dark' : 'light'
        );
    }, [isDarkTheme])

    return (
        <ThemeContext.Provider value={providerInterface}>
            {children}
        </ThemeContext.Provider>
    )
}