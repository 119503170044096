import useTG from '@/shared/hooks/useTG';
import Navbar from '@/widgets/Navbar/Navbar';
import React, { ReactElement, useCallback, useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import './PageLayout.scss';
import Background from '../../background/Background';
import useFetch from '@/shared/hooks/useFetch';
import api from '@/shared/api/api';
import { UserResponseDTO, UserStatus } from '@/shared/api/gen/data-contracts';
import { UserDataContext } from '@/app/context/UserDataContext';

interface Props {
	navbar?: boolean;
	background?: boolean;
	children: React.ReactElement;
	backgroundStyles?: any;
	closeButton?: boolean;
}
export const defaultButtonClickHandler = (navigate: NavigateFunction) => {
	navigate(-1);
};
/**
 * Переиспользуемый компонент лейаута страницы
 *
 * @param navbar - показывает Navbar или скрывает его
 * @param children - содержимое страницы
 * @returns children
 */
function PageLayout({ navbar, background, children, backgroundStyles, closeButton }: Props) {
	const { WebApp } = useTG({ requestWriteAccess: true });
	const navigate = useNavigate();
	const { data: userData, loading, error, refresh } = useContext(UserDataContext);
	const buttonClick = () => {
		navigate(-1);
	};

	React.useEffect(() => {
		if (!loading) {
			if (userData?.status === UserStatus.NOT_AUTH) {
				navigate('/registration');
			}
			if (userData?.status === UserStatus.BLOCKED) {
				navigate('/block');
			}
		}
	}, [userData, loading]);

	React.useEffect(() => {
		if (!closeButton) {
			if (WebApp?.onEvent) {
				WebApp?.BackButton?.show();
				WebApp?.onEvent('backButtonClicked', buttonClick);
				return () => {
					WebApp?.offEvent('backButtonClicked', buttonClick);
					WebApp?.BackButton?.hide();
				};
			}
		} else {
			WebApp?.BackButton?.hide();
		}

	}, [WebApp]);
	if (!userData?.status || loading) return null;
	return (
		<div className='PageLayout FadeIn'>
			<div className={`PageLayout__content invisibleScroll ${navbar && 'navbar'}`}>
				{background && <Background style={backgroundStyles} />}
				{children}
			</div>
			{navbar && (
				<div className='PageLayout__navbar'>
					<Navbar />
				</div>
			)}
		</div>
	);
}

export default PageLayout;
