import React from 'react';
import './index.scss';
import {FlexColumn} from "@/shared/ui/layouts/Flex/Flex";
import bookmark from 'public/images/bookmark.png';
import {EventLinksListResponseDTO} from "@/shared/api/gen/data-contracts";
import bookmarkActive from 'public/images/BookmarkActive.png';
import api from "@/shared/api/api";

type Props = {
    link: EventLinksListResponseDTO;
    refresh: () => void;
}

const LinkMaterial = ({link, refresh}: Props) => {
    const handleWishlist = (e) => {
        e.stopPropagation();
        if (link.in_wishlist){
            api.events.removeEventFileFromWishlistApiEventsEventLinksWishlistLinkIdDelete(link.id)
                .then(() => refresh());
        }
        else{
            api.events.addEventFileToWishlistApiEventsEventLinksWishlistLinkIdPost(link.id)
                .then(() => refresh());
        }
    }
    return (
        <div className='LinkMaterial'>
            <FlexColumn style={{alignItems: 'flex-start'}}>
                <h1 className='text-16 medium'>{link.title}</h1>
                {link.description && <h2 className='text-14 medium'>{link.description}</h2>}
                <a href={link.link} className='text-14 medium'>{link.link}</a>
            </FlexColumn>
            <img
                src={link.in_wishlist ? bookmarkActive : bookmark}
                alt='favourite'
                className='FileMaterial_favourite'
                onClick={(e) => handleWishlist(e)}
            />
        </div>
    );
};

export default LinkMaterial;