/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EventFAQListResponseDTO, HTTPValidationError } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EventFaq<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags EventFAQ, EventFAQ
   * @name GetEventFaqApiEventFaqGet
   * @summary Get Event Faq
   * @request GET:/api/event_faq
   * @response `200` `(EventFAQListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventFaqApiEventFaqGet = (
    query: {
      /** Event Ref */
      event_ref: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<EventFAQListResponseDTO[], HTTPValidationError>({
      path: `/api/event_faq`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
