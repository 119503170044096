import React from 'react';
import './index.scss';

type Props = {
    children: string | React.ReactNode;
}

function Chip({ children, ...props }: any) {
    return (
        <div className={`Chip ${props?.className}`}>
            {children}
        </div>
    );
}

export default Chip;