import React, { SetStateAction } from 'react';
import './index.scss';
import { Emoji } from '@/widgets/events/FeedbackModal/FeedbackModal';

type Props = {
	emoji: Emoji;
	icon: string;
	active: Emoji | undefined;
	setActive: React.Dispatch<SetStateAction<any>>;
};

const RatingEmoji = ({ emoji, icon, active, setActive }: Props) => {
	return (
		<div
			className={`RatingEmoji ${active == emoji && 'RatingEmoji_active'}`}
			onClick={() => setActive(emoji)}>
			<img
				src={icon}
				alt='rating'
			/>
		</div>
	);
};

export default RatingEmoji;
