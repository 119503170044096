import React from 'react';
import './index.scss';
import arrow_right from "public/images/ArrowRight.png";
import close from 'public/images/close.png';

type Props = {
    text: string;
    icon?: string;
    onClick: () => void;
}

const ArrowButton = ({text, icon, onClick}: Props) => {
    return (
        <button
            className='ArrowButton'
            onClick={onClick}
        >
            <div>
                <img
                    src={icon != null ? icon : close}
                    alt='close'
                />
                <span className='text-16 medium'>{text}</span>
            </div>
            <img
                src={arrow_right}
                alt='arrow'
            />
        </button>
    );
};

export default ArrowButton;