import { FaqLayout } from '@/pages/SupportPage';
import api from '@/shared/api/api';
import { EventFAQListResponseDTO, ServerApiEventsClientDtoEventsResponseDTO } from '@/shared/api/gen/data-contracts';
import useFetch from '@/shared/hooks/useFetch';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import React from 'react';
import { useParams } from 'react-router-dom';

interface Props {}

function EventFAQ() {
	const { id } = useParams<'id'>();
	const { data: event, error: EventError } = useFetch<ServerApiEventsClientDtoEventsResponseDTO>(
		() => api.events.getEventsDetailApiEventsIdGet(id as unknown as number),
		// .catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`)),
		{} as ServerApiEventsClientDtoEventsResponseDTO,
		[]
	);
	const { data } = useFetch<EventFAQListResponseDTO[]>(
		() => api.eventFaq.getEventFaqApiEventFaqGet({ event_ref: id ? +id : null }),
		[],
		[id]
	);
	return (
		<PageLayout>
			<FaqLayout
				faqs={data}
				supportUrl={event.help_url}
			/>
		</PageLayout>
	);
}

export default EventFAQ;
