import React from 'react';
import InputMask from 'react-input-mask';
import './index.scss';
import { FieldError, FieldErrorsImpl, FieldName, Merge, useController, UseFormRegisterReturn } from 'react-hook-form';

// NOTE: Пофиксил типизацию, Разделил инпуты
interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	field: string;
	register: UseFormRegisterReturn;
	error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
	label?: string;
	placeholder?: string;
	control?: any;
}
/**
 * Текстовый инпут для react-hook
 * @param param0
 * @returns
 */
export function TextAreaInput({ register, error, label, placeholder, ...props }: Props) {
	const { onKeyDown } = props;
	return (
		<div className='TextInput'>
			<label className='text-16 regular'>{label}</label>
			<textarea
				{...register}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
			/>
			<h1 className='text-12 regular'>{error as string}</h1>
		</div>
	);
}

/**
 * Текстовый инпут для react-hook
 * @param param0
 * @returns
 */
function TextInput({ register, error, label, placeholder, ...props }: Props) {
	const { onKeyDown } = props;
	return (
		<div className='TextInput'>
			<label className='text-16 regular'>{label}</label>
			<input
				{...register}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
			/>
			<h1 className='text-12 regular'>{error as string}</h1>
		</div>
	);
}
interface IMaskedInput extends Props {
	mask: string;
}
/**
 * Текстовый инпут с маской для react-hook
 * @param param0
 * @returns
 */
export function MaskedInput({ mask, register, error, label, placeholder, ...props }: IMaskedInput) {
	const { onKeyDown } = props;
	return (
		<div className='TextInput'>
			<label className='text-16 regular'>{label}</label>
			<InputMask
				{...register}
				mask={mask}
				placeholder={placeholder}
				onKeyDown={onKeyDown}>
				{(inputProps) => <input {...inputProps} />}
			</InputMask>
			<h1 className='text-12 regular'>{error as string}</h1>
		</div>
	);
}
/**
 * Текстовый инпут с маской для даты для react-hook
 * @param param0
 * @returns
 */
export function TextDateInput({ field, control, register, error, label, placeholder, ...props }: Props) {
	const { onKeyDown } = props;
	const { field: FieldData, fieldState } = useController({ name: field, control });
	const parseFrom = (date: string) => {
		if (date) {
			const parts = date.split('-');
			return `${parts[2]}/${parts[1]}/${parts[0]}`;
		}
		return null;
	};
	const parseTo = (date: string) => {
		const parts = date.split('/');
		return `${parts[2]}-${parts[1]}-${parts[0]}`;
	};
	return (
		<div className='TextInput'>
			<label className='text-16 regular'>{label}</label>
			<InputMask
				mask='99/99/9999'
				placeholder={placeholder}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					let birthday = event.target.value;
					if (birthday) {
						event.target.value = parseTo(birthday);
					} else {
						// @ts-ignore
						event.target.value = null;
					}
					console.log('event Date data', event);
					FieldData.onChange(event);
				}}
				// @ts-ignore
				value={parseFrom(FieldData.value)}
				onKeyDown={onKeyDown}>
				{(inputProps) => <input {...inputProps} />}
			</InputMask>
			<h1 className='text-12 regular'>{error as string}</h1>
		</div>
	);
}
/**
 * Текстовый инпут с маской телефона для react-hook
 * @param param0
 * @returns
 */
export function TextPhoneInput(props: Props) {
	return (
		<MaskedInput
			{...props}
			mask='+7 (999) 999-99-99'
		/>
	);
}

export default TextInput;
