import React from 'react';
import './index.scss';
import filePlaceholder from 'public/images/FilePlaceholder.png';
import bookmark from 'public/images/bookmark.png';
import {FlexColumn, FlexRow} from "@/shared/ui/layouts/Flex/Flex";
import {ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO} from "@/shared/api/gen/data-contracts";
import bookmarkActive from 'public/images/BookmarkActive.png';
import api from "@/shared/api/api";

type Props = {
    file: ServerApiEventsEventFilesClientDtoEventFilesListResponseDTO;
    refresh: () => void;
}

const FileMaterial = ({file, refresh}: Props) => {
    const handleWishlist = (e) => {
        e.stopPropagation();
        if (file.in_wishlist){
            api.events.removeEventFileFromWishlistApiEventsEventFilesWishlistFileIdDelete(file.id)
                .then(() => refresh());
        }
        else{
            api.events.addEventFileToWishlistApiEventsEventFilesWishlistFileIdPost(file.id)
                .then(() => refresh());
        }
    }
    return (
        <div className='FileMaterial'>
            <FlexRow className='g-16'>
                <img
                    src={filePlaceholder}
                    alt='file'
                    className='FileMaterial_placeholder'
                />
                <FlexColumn style={{alignItems: 'flex-start'}}>
                    <h1 className='text-16 medium'>{file.file}</h1>
                    <h2 className='text-14 medium'>{file.file}</h2>
                </FlexColumn>
            </FlexRow>
            <img
                src={file.in_wishlist ? bookmarkActive : bookmark}
                alt='favourite'
                className='FileMaterial_favourite'
                onClick={(e) => handleWishlist(e)}
            />
        </div>
    );
};

export default FileMaterial;