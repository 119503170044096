interface GetShortTextOptions {
    maxLength: number;
    unparseHtml: boolean;
    fullTextUnparse: boolean;
}
const defaultOptions: GetShortTextOptions = {
    maxLength: 70,
    unparseHtml: true,
    fullTextUnparse: true
}
interface GetShortTextResponse {
    text: string;
    mutated: boolean;
}
/**
 *
 * Сокращает текст полученной строки по точке, пробелу или в любом месте, не превышая лимит максимальной длины символов.
 * По умолчанию парсит html и берет только текст.
 *
 * @param {string | RichTextString} text - text to be shorted
 * @param {GetShortTextOptions} options - additional options
 * @returns string | RichTextString
 */
export function getShortText(text: string, options: Partial<GetShortTextOptions> = defaultOptions): GetShortTextResponse {
    const adjProps: GetShortTextOptions = { ...defaultOptions, ...options }
    const { maxLength, unparseHtml, fullTextUnparse } = adjProps;

    if (text.length <= maxLength && !fullTextUnparse) return { text, mutated: false }

    if (unparseHtml) {
        const parser = new DOMParser();
        text = parser.parseFromString(text, "text/html").body.textContent || "";
    }

    if (text.length <= maxLength && fullTextUnparse) return { text, mutated: false }

    const shortText = text.slice(0, maxLength + 1);
    const dotIndex: number = shortText.lastIndexOf('.');

    if (dotIndex !== -1) {
        return { text: shortText.slice(0, dotIndex + 1), mutated: true }
    }
    const spaceIndex: number = shortText.lastIndexOf(' ')
    if (spaceIndex !== -1) {
        return { text: shortText.slice(0, spaceIndex + 1), mutated: true }
    }
    return { text: `${shortText}...`, mutated: true }
}