import { useState, useEffect, useCallback } from 'react';
import { HttpResponse } from '../api/gen/http-client';

export type UseFetchProps<T> = {
	fetch: () => Promise<T>;
	initialValue?: T;
	dependencies: Array<any>;
	options?: Partial<FetchOptions>;
};

interface FetchOptions {
	autoRefresh: boolean;
	refreshTiming?: number;
}
const defaultOptions: FetchOptions = {
	autoRefresh: false,
	refreshTiming: 30,
};
/**
 * Хук для управления запросами
 * @param fetch - функция запроса
 * @param initialValue - значение по умолчанию
 * @param dependencies - зависимости для обновления запроса
 * @param options - дополнительные опции для запроса. Например, автообновление данных
 * @returns
 */
function useFetch<T>(
	fetch: () => Promise<HttpResponse<T, any>>,
	initialValue?: T,
	dependencies: Array<any> = [],
	options: Partial<FetchOptions> = { autoRefresh: false }
) {
	const useOptions = { ...defaultOptions, ...options };

	const [data, setData] = useState<T>(initialValue as T);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<any | null>();
	const [isRefreshed, setIsRefreshed] = useState<boolean>(false);

	const doFetch = useCallback(async () => {
		try {
			const response = await fetch();
			setData(response?.data);
			setLoading(false);
		} catch (err) {
			setError(err);
			setLoading(false);
		}
	}, [...dependencies]);

	const refresh = async () => {
		doFetch();
		setIsRefreshed((prev) => true);
	};

	useEffect(() => {
		doFetch();
	}, [doFetch]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (useOptions.autoRefresh) {
			const intervalID = setInterval(() => {
				setIsRefreshed((prev) => true);
				doFetch();
			}, (useOptions.refreshTiming || 30) * 1000);
			return () => {
				clearInterval(intervalID);
			};
		}
	}, [doFetch]);

	return { data, refresh, loading, error, isRefreshed };
}
export default useFetch;
