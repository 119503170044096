/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HTTPValidationError,
  OrganizationsListResponseDTO,
  ServerApiOrganizationsClientDtoOrganizationsResponseDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Organizations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Organizations, Organization
   * @name GetOrganizationsApiOrganizationsGet
   * @summary Get Organizations
   * @request GET:/api/organizations
   * @response `200` `(OrganizationsListResponseDTO)[]` Successful Response
   */
  getOrganizationsApiOrganizationsGet = (params: RequestParams = {}) =>
    this.request<OrganizationsListResponseDTO[], any>({
      path: `/api/organizations`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organizations, Organization
   * @name GetOrganizationsDetailApiOrganizationsIdGet
   * @summary Get Organizations Detail
   * @request GET:/api/organizations/{_id}
   * @response `200` `ServerApiOrganizationsClientDtoOrganizationsResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getOrganizationsDetailApiOrganizationsIdGet = (id: number, params: RequestParams = {}) =>
    this.request<ServerApiOrganizationsClientDtoOrganizationsResponseDTO, HTTPValidationError>({
      path: `/api/organizations/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
