/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContactRequestCreateDTO, ContactRequestResponseDTO, HTTPValidationError } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ContactRequests<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContactRequests, ContactRequests
   * @name CreateContactRequestApiContactRequestsPost
   * @summary Create Contact Request
   * @request POST:/api/contact_requests
   * @response `200` `ContactRequestResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  createContactRequestApiContactRequestsPost = (data: ContactRequestCreateDTO, params: RequestParams = {}) =>
    this.request<ContactRequestResponseDTO, HTTPValidationError>({
      path: `/api/contact_requests`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
