import React, { useEffect } from 'react';
import ChipField from '@/shared/ui/checkboxChip/CheckboxChip';
import activeIcon from 'public/images/checkbox_on.svg';
import unActiveIcon from 'public/images/checkbox_off.svg';
import './index.scss';
import { FlexRow } from '../../layouts/Flex/Flex';

type Props = {
	title: string;
	active: boolean;
	onChange: () => void;
	variant?: string
};

export const CheckboxInputChip = ({ title, active, onChange, variant }: Props) => {
	return (
		<ChipField
			variant={variant || 'white'}
			style={{ width: '100%' }}>
			<FlexRow
				className='Checkbox g-12'
				style={{ width: '100%', justifyContent: 'flex-start' }}
				onClick={onChange}>
				<input type='checkbox' />
				{active ? (
					<img
						src={activeIcon}
						alt='checkbox'
					/>
				) : (
					<img
						src={unActiveIcon}
						alt='checkbox'
					/>
				)}
				<span className='text-14 regular'>{title}</span>
			</FlexRow>
		</ChipField>
	);
};
