import React from 'react';
import './index.scss';

const EventRegistrationCard = ({children}: any) => {
    return (
        <div className='EventRegistrationCard'>
            {children}
        </div>
    );
};

export default EventRegistrationCard;