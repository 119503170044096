import React from 'react';
import './index.scss';

interface Props {}

function Grid({ ...props }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
	const { children, className, ...restProps } = props;
	return (
		<div
			className={`Grid ${className && className}`}
			{...restProps}>
			{props.children}
		</div>
	);
}

export default Grid;
