import api from '@/shared/api/api';
import { UserResponseDTO } from '@/shared/api/gen/data-contracts';
import useFetch from '@/shared/hooks/useFetch';
import { error } from 'console';
import React, { useState } from 'react';

interface IUserDataContext {
	data?: UserResponseDTO;
	loading: boolean;
	refresh: () => any;
	error: any;
}
export const UserDataContext = React.createContext<IUserDataContext>({} as IUserDataContext);

export default function UserDataContextProvider({ children }: any) {
	const { data, loading, refresh, error } = useFetch(
		() => api.users.getUserProfileApiUsersProfileGet(),
		{} as UserResponseDTO,
		[]
	);
	const providerInterface = React.useMemo(() => ({ data, loading, refresh, error }), [data, loading]);
	return <UserDataContext.Provider value={providerInterface}>{children}</UserDataContext.Provider>;
}
