import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import useTG from '@/shared/hooks/useTG';
import '@/shared/styles/blank.scss';
import '@/shared/styles/fonts.scss';
import '@/shared/styles/main.scss';
import '@/shared/styles/animations.scss';
import { ProviderStore } from './context/ProviderStore';
import EventList from '@/pages/events/EventList/EventList';
import EventPage from '@/pages/events/EventPage/EventPage';
// import PhotoPage from '@/pages/PhotoPage';
import FavouritesPage from '@/pages/FavouritesPage';
// import SupportPage from '@/pages/SupportPage';
import AboutPage from '@/pages/AboutPage/AboutPage';
import EventFAQ from '@/pages/events/EventFAQ';
import LoadPage from '@/pages/general/LoadPage';
import ErrorPage from '@/pages/general/ErrorPage';

const Index = lazy(() => import('@/pages/IndexPage/Index'));
// NOTE: Добавил страницу
const RegistrationPage = lazy(() => import('@/pages/users/RegistrationPage'));
const ProfilePage = lazy(() => import('@/pages/users/ProfilePage'));
const EditProfilePage = lazy(() => import('@/pages/users/EditProfilePage'));
const MainPage = lazy(() => import('@/pages/MainPage'));
const UserEvents = lazy(() => import('@/pages/users/UserEvents'));
const EventsMaterials = lazy(() => import('@/pages/events/EventMaterials'));
//почему-то EventList и EventPage не находятся при lazy импорте...

function AppRouter() {
	const { WebApp } = useTG({ requestWriteAccess: true });

	React.useEffect(() => {
		WebApp?.expand();
	}, [WebApp]);
	return (
		<Suspense fallback={<LoadPage />}>
			<ProviderStore>
				<Router>
					<Routes>
						{/* <Route
							path='/'
							element={<Index />}
						/> */}
						<Route
							path='/'
							element={<MainPage />}
						/>
						<Route
							path='/about'
							element={<AboutPage />}
						/>
						<Route
							path='/registration'
							element={<RegistrationPage />}
						/>
						<Route
							path='/profile'
							element={<ProfilePage />}
						/>
						<Route
							path='/profile/edit'
							element={<EditProfilePage />}
						/>
						<Route
							path='/profile/events'
							element={<UserEvents />}
						/>
						<Route
							path='/events'
							element={<EventList />}
						/>
						<Route
							path='/events/:id'
							element={<EventPage />}
						/>
						<Route
							path='/events/:id/support'
							element={<EventFAQ />}
						/>
						<Route
							path='/events/:id/materials'
							element={<EventsMaterials />}
						/>
						<Route
							path='/favourites'
							element={<FavouritesPage />}
						/>
						<Route
							path='/test'
							element={<ErrorPage />}
						/>
						<Route
							path='/*'
							element={<ErrorPage />}
						/>
						{/* <Route
							path='/support'
							element={<SupportPage />}
						/> */}
					</Routes>
				</Router>
			</ProviderStore>
		</Suspense>
	);
}

export default AppRouter;
