import React, { useState } from 'react';
import './index.scss';

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    name: string;
    option1: string | JSX.Element;
    option2: string | JSX.Element;
    // @ts-ignore
    onChange: (value: "option1"|"option2")=>any;
}

function Tabs({name, option1, option2, onChange, ...props}: Props) {
    const [option, setOption] = useState<"option1"|"option2">("option1");
    const changeOption = (value: "option1"|"option2") => {setOption(prev=>value); onChange(value)}
    return (
        <div className='Tabs' {...props}>
            <div className={`Tabs__option1 text-16 medium ${option}`} onClick={()=>changeOption("option1")}>
                {option1}
            </div>
            <div className={`Tabs__option2 text-16 medium ${option}`} onClick={()=>changeOption("option2")}>
                {option2}
            </div>
            <div className={`Tabs__background ${option}`}/>
        </div>
    )
}

export default Tabs