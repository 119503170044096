import React from 'react';
import './index.scss';

export enum ButtonVariant {
	default = 'DefaultButton',
	orange = 'OrangeButton',
	reverse = 'ReverseButton',
	grey = 'GreyButton',
}

interface DefaultButtonProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	children: string | React.ReactNode;
	theme?: ButtonVariant;
}

export default function CustomButton({ children, theme = ButtonVariant.default, ...props }: DefaultButtonProps) {
	return (
		<button
			{...props}
			className={`${theme} ${props?.className}`}
			style={props?.style}>
			{children}
		</button>
	);
}
