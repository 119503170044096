import React, { useState } from 'react';
import './index.scss';
import arrowDown from 'public/images/arrow_down.png';
import { AnimatePresence, motion } from 'framer-motion';
import RichText from '../RichText/RichText';
import { EventFAQListResponseDTO } from '@/shared/api/gen/data-contracts';

type Props = {
	question: string;
	answer: string;
};

const DropdownList = (faq: EventFAQListResponseDTO) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	return (
		<div className='DropdownList'>
			<div
				className={`DropdownList_question ${isExpanded && 'DropdownList_expanded'}`}
				onClick={() => setIsExpanded((prev) => !prev)}>
				<h1 className='text-18 medium'>{faq.title}</h1>
				<img
					src={arrowDown}
					alt='arrow'
				/>
			</div>
			<AnimatePresence>
				{isExpanded && (
					<motion.div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							background: '#EBF0F6',
						}}
						initial={{ height: '0px' }}
						animate={{ height: 'fit-content' }}
						exit={{ height: '0px' }}
						transition={{ ease: 'easeInOut', duration: 0.2 }}>
						<hr
							style={{ width: '100%', border: 'none', borderTop: '1px solid #DEE6F0', marginTop: '8px' }}
						/>
						<RichText text={faq.text} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default DropdownList;
